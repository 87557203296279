<template>
  <div class="slotCom">
    <slot></slot>
  </div>
</template>

<script>
export default {
 name: 'slotCom',
  data() {
    return {

    }
  },
  components: {},
  watch: {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">

</style>
