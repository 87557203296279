<template>
  <div class="editproduceResource">
    <!-- 设备信息 -->
    <el-form ref="form" :model="form" class="content property" label-position="top" :rules="rules">
      <el-form-item class="item pro" label="属性" prop="category">
        <el-select v-model="form.category">
          <el-option v-for="item in categoryList" :value="item" :key="item"/>
        </el-select>
      </el-form-item>
      <el-form-item class="item" label="设备编号" prop="equipmentNo">
        <el-input v-model="form.equipmentNo" clearable/>
      </el-form-item>
      <el-form-item class="item" label="设备名称" prop="equipmentName">
        <el-input v-model="form.equipmentName" clearable/>
      </el-form-item>
      <el-form-item class="item" label="设备型号" prop="equipmentParameters">
        <el-input v-model="form.equipmentParameters" clearable/>
      </el-form-item>
      <el-form-item class="item" label="计划停机时长（min）" prop="scheduledDownTime">
        <el-input class="hasAfter" v-model="form.scheduledDownTime" clearable @input="output('form','scheduledDownTime')"/>
      </el-form-item>
      <el-form-item class="item" label="设备状态" prop="equipmentStatus">
        <el-radio v-model="form.equipmentStatus" :label="1">正常</el-radio>
        <el-radio v-model="form.equipmentStatus" :label="2">故障</el-radio>
      </el-form-item>
      <el-form-item v-if="form.equipmentStatus==2" class="item errorTime" label="故障时间" prop="faultTime">
        <el-date-picker
          v-model="form.faultTime"
          type="datetimerange"
          range-separator="~"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm"
          start-placeholder="开始时间"
          end-placeholder="结束时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item v-if="form.equipmentStatus==2" class="item" label="故障时长">
        <el-input v-model="form.faultDuration" clearable disabled/>
      </el-form-item>
      <el-form-item class="item orderType" label="接单方式" prop="receiveOrderType">
        <el-radio v-model="form.receiveOrderType" :label="1">正常</el-radio>
        <el-radio v-model="form.receiveOrderType" :label="2">散件</el-radio>
      </el-form-item>
      <el-form-item class="item" label="日历方案" prop="workshiftSchemeName">
        <el-select v-model="form.workshiftSchemeName" filterable>
          <el-option 
            v-for="(v,i) in maintenancePlanList" 
            :key="i" :value="v.workshiftSchemeName" :label="v.workshiftSchemeName"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="item" label="机器人配置" prop="robotName">
        <el-select v-model="form.robotName">
          <el-option v-for="item in robotTypeList" :value="item" :key="item"/>
        </el-select>
      </el-form-item>
    </el-form>
    <!-- 机器人时间 -->
    <el-form ref="form2" :model="form" v-if="form.robotName!='无配置机器人'" class="content robot" label-position="top" :rules="rules">
      <div class="title">机器人时间（min）</div>
      <el-form-item class="item" label="调机时间" prop="robotShuntingTime">
        <el-input v-model="form.robotShuntingTime" clearable @input="output('form','robotShuntingTime')"/>
      </el-form-item>
      <el-form-item class="item" label="快反调机时间" prop="robotShuntingTimeFastReflex">
        <el-input v-model="form.robotShuntingTimeFastReflex" clearable @input="output('form','robotShuntingTimeFastReflex')"/>
      </el-form-item>
      <el-form-item class="item" label="装夹时间" prop="robotClampingTime">
        <el-input v-model="form.robotClampingTime" clearable @input="output('form','robotClampingTime')"/>
      </el-form-item>
      <el-form-item class="item" label="快反装夹时间" prop="robotClampingTimeFastReflex">
        <el-input v-model="form.robotClampingTimeFastReflex" clearable @input="output('form','robotClampingTimeFastReflex')"/>
      </el-form-item>
    </el-form>
    <!-- 通用加工范围 -->
    <div class="process">
      <div class="title">适用加工范围</div>
      <el-table :data="processData" header-cell-class-name="headerStyle" style="margin:10px auto">
        <template  v-for="(item,index) in processColumn">
          <el-table-column v-bind="item" :key="index" v-if="!item.show || item.show.includes(form.category)">
            <template slot-scope="scope">
              <component 
                v-if="item.type!=='index'" 
                :is="item.component"
                v-bind="item.attribute"
                v-model="processData[scope.$index][item.prop]"
                @input="validateInput(scope.$index,item)"
              >
                <img class="delbtn"
                  v-if="item.label==='操作'" 
                  src="@/assets/images/dataManage/del.png"
                  @click="deleteItem('processData',scope.$index)"
                />
                <template v-if="item.label==='优先级'">
                  <el-option v-for="(item,index) in priorityList" :key="index" :value="item"></el-option>
                </template>
                <template v-else-if="item.label==='设备类别'">
                  <el-option v-for="(item,index) in equipmentTypeList" :key="index" :value="item"></el-option>
                </template>
              </component>
              <span v-else>{{scope.$index+1}}</span>
            </template>
            <template slot="header" v-if="item.renderHead">
              <span class="renderHead">{{item.label}}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <el-button size="small" @click="addItem('processData')">新增</el-button>
    </div>
    <!-- 保养计划 -->
    <div class="plan">
      <el-checkbox v-model="planChecked">添加保养计划</el-checkbox>
      <div v-if="planChecked">
        <el-table :data="planData" header-cell-class-name="headerStyle" style="margin:16px auto">
          <el-table-column v-for="(item) in planColumn" :key="item.label" v-bind="item">
            <template slot-scope="scope">
             <component 
              v-if="item.type!=='index'" 
              :is="item.component" 
              v-model="planData[scope.$index][item.prop]"
              v-bind="item.attribute"
              @change="changeValue(scope.$index,item)"
            >
              <img class="delbtn"
                v-if="item.label==='操作'" 
                src="@/assets/images/dataManage/del.png"
                @click="deleteItem('planData',scope.$index)"
              />
              <span v-else>{{scope.row[item.prop]}}</span>
             </component>
             <span v-else>{{scope.$index+1}}</span>
            </template>
            <template slot="header" v-if="item.renderHead">
              <span class="renderHead">{{item.label}}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-button size="small" @click="addItem('planData')">新增</el-button>
      </div>
    </div>
    <div class="footer">
      <el-button @click="$router.push('/produceResource')">取消</el-button>
      <el-button type="primary" @click="checkData">确认</el-button>
    </div>
  </div>
</template>

<script>
import slotCom from './components/slotCom.vue'
import { 
  addProEquipment,
  editProEquipment,
  getWorkshift,
  getProEquipment
  } from '@/api/data.js'
export default {
 name: 'editproduceResource',
  data() {
    return {
      categoryList:['数铣','数车','线割机','其他'],
      robotTypeList:['高侍','高寻','无配置机器人'],
      maintenancePlanList:[],
      form:{
        category:'数铣',
        equipmentNo:'',
        equipmentName:'',
        equipmentParameters:'',
        equipmentStatus:1,
        faultStartTime:'',
        faultEndTime:'',
        scheduledDownTime:'',
        faultTime:null,
        faultDuration:'',
        receiveOrderType:1,
        workshiftSchemeName:'',
        robotName:'高侍',
        robotClampingTime:'',//机器人装夹时间(分钟)
        robotClampingTimeFastReflex:'',//机器人快反装夹时间(分钟)
        robotShuntingTime:'',//机器人调机时间(分钟)
        robotShuntingTimeFastReflex:'',//机器人快反调机时间(分钟)
      },
      rules:Object.freeze({
        category:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        equipmentNo:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        equipmentName:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        equipmentType:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        equipmentParameters:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        equipmentStatus:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        scheduledDownTime:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        faultDuration:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        faultTime:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        receiveOrderType:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        workshiftSchemeName:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        robotName:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        robotClampingTime:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        robotClampingTimeFastReflex:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        robotShuntingTimeFastReflex:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        robotShuntingTime:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ]
      }),
      processColumn:Object.freeze([
        {
          label:'序号',
          prop:'id',
          type:'index',
          width:100,
          component:'slotCom',
        },{
          label:'优先级',
          prop:'equipmentPriority',
          component:'el-select',
          'min-width':120,
          renderHead:true
        },{
          label:'设备类别',
          prop:'supportedEquipmentType',
          component:'el-select',
          'min-width':120
        },{
          label:'长（mm）',
          prop:'length',
          component:'el-input',
          attribute:{placeholder: '请输入数字'},
          'min-width':130,
          show:'数铣,线割机,其他'
        },{
          label:'高（mm）',
          prop:'height',
          component:'el-input',
          attribute:{placeholder: '请输入数字'},
          'min-width':130,
          show:'数铣,线割机,其他'
        },{
          label:'宽（mm）',
          prop:'width',
          component:'el-input',
          attribute:{placeholder: '请输入数字'},
          'min-width':130,
          show:'数铣,线割机,其他'
        },{
          label:'最大旋转直径（mm）',
          attribute:{placeholder: '请输入数字'},
          prop:'maxRotationDiameter',
          component:'el-input',
          'min-width':250,
          show:'数铣'
        },{
          label:'能穿进主轴孔最大直径Ø（mm）',
          attribute:{placeholder: '请输入数字'},
          prop:'diameterThroughspindle',
          component:'el-input',
          'min-width':300,
          show:'数车'
        },{
          label:'能穿进主轴孔最长范围（mm）',
          attribute:{placeholder: '请输入数字'},
          prop:'lengthThroughSpindle',
          component:'el-input',
          'min-width':300,
          show:'数车'
        },{
          label:'不穿进主轴孔最大直径Ø（mm）',
          attribute:{placeholder: '请输入数字'},
          prop:'diameter',
          component:'el-input',
          'min-width':300,
          show:'数车'
        },{
          label:'不穿过主轴孔最长范围（mm）',
          attribute:{placeholder: '请输入数字'},
          prop:'length',
          component:'el-input',
          'min-width':300,
          show:'数车'
        },{
          label:'操作',
          fixed:'right',
          'min-width':100,
          component:'slotCom',
        }
      ]),
      processData:[],
      planColumn:Object.freeze([
        {
          label:'序号',
          prop:'id',
          type:'index',
          width:100,
        },{
          label:'保养时间',
          prop:'maintenanceTime',
          component:'el-date-picker',
          attribute:{
            type:"datetimerange",
            'range-separator':"~",
            'start-placeholder':"开始时间",
            'end-placeholder':"结束时间",
            'value-format':"yyyy-MM-dd HH:mm:ss",
            'format':'yyyy-MM-dd HH:mm'
            },
          renderHead:true
        },{
          label:'保养时长',
          prop:'maintenanceDuration',
          width:300,
          component:'slotCom',
        },{
          label:'保养内容',
          prop:'maintenanceContent',
          component:'el-input',
          renderHead:true
        },{
          label:'操作',
          fixed:'right',
          width:160,
          component:'slotCom',
        }
      ]),
      planData:[],
      planChecked:false,
      priorityList:[1,2,3,4,5,6,7,8,9,10],
      test:'',
    }
  },
  components: {
    slotCom
  },
  computed: {
    equipmentTypeList:{
      set(){},
      get(){
        let list = {
          数车:['数车','数车尾顶','车铣','车铣尾顶','车铣双主轴'],
          线割机:['慢走丝'],
          数铣:['三轴','四轴','五轴','三轴快反','四轴快反','五轴快反','三轴零点','四轴零点','五轴零点'],
          其他:['钻床','车床','铣床','锯床','平面磨','外圆磨','烤箱','激光雕刻机','攻牙机','抛光机','CMM'],
        }
        return this.form.category ? list[this.form.category] : []
      },
    }
  },
  watch: {
    'form.faultTime':{
      handler(val){
        if(val){
          this.$set(this.form,'faultDuration',this.convertMsToTime(val))
          // this.$set(this.form,'faultStartTime',val[0])
          // this.$set(this.form,'faultEndTime',val[1])
        }
      }
    }
  },
  mounted() {
    
  },
  activated() {
    this.getWorkList()
    if(this.$route.query.id){
      this.getDetails(this.$route.query.id)
    }else{
      Object.assign(this.$data,this.$options.data())
      this.$nextTick(()=>{
        this.$refs['form'].clearValidate()
        this.$refs['form2']?.clearValidate()
      })
    }
  },
  deactivated() {
    this.$refs['form'].clearValidate()
    this.$refs['form2']?.clearValidate()
  },
  methods: {
    // 获取日历方案列表
    getWorkList(){
      let params = {
        page:1,
        size:999
      }
      getWorkshift(params).then(res=>{
        if(res.code === 0){
          this.maintenancePlanList = res.data.records
        }else{
          this.$message.error(res.msg || '接口出错')
        }
      }).catch(()=>{
        this.$message.error('接口出错')
      })
    },
    // 查看详情
    getDetails(id){
      getProEquipment(id).then(res=>{
        if(res.code === 0){
          for(let key in this.form){
            if(key === 'faultTime'){
              this.$set(this.form,key,res.data.faultStartTime ? [res.data.faultStartTime,res.data.faultEndTime] : null)
            }else{
              this.$set(this.form,key,res.data[key])
            }
          }
          this.processData = res.data.priorityEquipmentProcessingScopeList || [];
          this.planData = res.data.maintenancePlans || [];
        }
      })
    },
    // 删除
    deleteItem(key,index){
      console.log('删除',{
        [key]:index
      })
      this[key].splice(index,1)
    },
    // 新增
    addItem(key){
      // this[key].push(this.$options.data()[key][0])
      if(key==='planData'){
        this[key].push({
          "maintenanceContent": "",
          "maintenanceTime":"",
          "maintenanceEndTime": "",
          "maintenanceStartTime": "",
          "maintenanceDuration": ""
        })
      }else{
        this[key].push({
          "diameter": '',
          "diameterThroughspindle": '',
          "equipmentPriority": '',
          "height": '',
          "length": '',
          "lengthThroughSpindle": '',
          "maxRotationDiameter": '',
          "supportedEquipmentType": '',
          "width": '',
        })
      }
    },
    // 输入数字
    validateInput(index,item){
      if(item.component!=='el-input'){
        return false
      }
      let regex = /^[0-9]*$/;
      let val = this.processData[index][item.prop]
      if(!regex.test(val)){
        this.$set(this.processData[index],[item.prop],val.replace(/[^0-9]/g, ''))
      }
    },
    output(key1,key2){
      // 只能输入数字
      this.$set(this[key1],key2,this[key1][key2].replace(/[^0-9]/g, ''))
    },
    changeValue(index,item){
      if(item.label=='保养时间'){
        // 计算保养时长
        let maintenanceTime = this.planData[index].maintenanceTime
        let time = this.convertMsToTime(maintenanceTime)
        this.$set(this.planData[index],'maintenanceDuration',time)
        this.$set(this.planData[index],'maintenanceStartTime',maintenanceTime[0])
        this.$set(this.planData[index],'maintenanceEndTime',maintenanceTime[1])
      }
    },
    // 计算时长
    convertMsToTime(timeRange) {
      let milliseconds = new Date(timeRange[1]).getTime() - new Date(timeRange[0]).getTime()
      var hours = Math.floor(milliseconds / (1000 * 60 * 60));
      var minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
      // var seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
      console.log('计算时长',timeRange,hours + "时 " + minutes + "分 ")
      return hours + "时 " + minutes + "分 ";
    },
    // 校验数据
    async checkData(){
      let result1,result2,result3,result4,result5;
      await this.$refs['form'].validate((valid) => {
        result1 = valid
      });
      if(this.$refs['form2']){
        await this.$refs['form2'].validate((valid) => {
          result2 = valid
        })
      }else{
        result2 = true
      }
      result3 = this.processData.every(v=>v.equipmentPriority)
      result4 = this.planData.every(v=>v.maintenanceTime)
      result5 = this.planData.every(v=>v.maintenanceContent)
      console.log({result1,result2,result3,result4})
      if(result1 && result2){
        if(!result3){
          return this.$message.warning('请选择加工范围优先级')
        }
        if(!result4){
          return this.$message.warning('请选择保养时间')
        }
        if(!result5){
          return this.$message.warning('请选择保养内容')
        }
        this.onSave()
      }else{
        this.$message.warning('请填写完整信息')
      }
    },
    // 保存
    onSave(){
      let params = {}
      Object.assign(params,
      {
        ...this.form,
        maintenancePlans: this.planData.map(({maintenanceTime,...rest})=>rest),
        priorityEquipmentProcessingScopeList:this.processData,
        shiftId:this.maintenancePlanList.find(v=>v.workshiftSchemeName==this.form.workshiftSchemeName)?.shiftId
      })
      if(this.$route.query.id){
        params.id = this.$route.query.id
        params.sortOrder = this.$route.query.sortOrder
      }
      if(this.form.equipmentStatus==2){
        params.faultStartTime = this.form.faultTime[0]
        params.faultEndTime = this.form.faultTime[1]
      }else{
        delete params.faultDuration
        delete params.faultStartTime
        delete params.faultEndTime
        // params.faultDuration = ''
        // params.faultStartTime = null
        // params.faultEndTime = null
      }
      delete params.faultTime
      if(this.form.robotName === '无配置机器人'){
        delete params.robotClampingTime
        delete params.robotClampingTimeFastReflex
        delete params.robotShuntingTime
        delete params.robotShuntingTimeFastReflex
      }
      let Api = this.$route.query.id ? editProEquipment : addProEquipment
      console.log('Edit ProEquipment',params)
      Api(params).then(res=>{
        if(res.code == 0){
          this.$message.success('操作成功')
          this.$router.push('/produceResource')
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.editproduceResource{
  text-align: left;
  padding: 18px 28px 50px;
  font-size: 16px;
  color:#E8E8E8;
  @mixin common{
    padding: 20px 26px;
    width: 100%;
    min-height: 100px;
    background: #232323;
    border-radius: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
  }
  .content{
    @include common();
    display: grid;
    grid-template-columns: 1fr,1fr,1fr,40%;
    grid-column-gap: 46px;
    padding-right: 100px;
    .pro,.orderType,.title{
      grid-column: span 4;
      .el-select{
        width: calc((100% - 52px)/4 - 23px);
      }
    }
    .errorTime{
      grid-column: span 2;
    }
    .hasAfter{
      &:after{
        content: '* 每日';
        position: absolute;
        display: inline-block;
        width: max-content;
        margin-left: 10px;
      }
    }
  }
  .process,.plan{
    @include common();
    .renderHead{
      &:before{
        content: "*";
        color: #F56C6C;
        margin-right: 5px
      }
    }
  }
  .delbtn{
    cursor: pointer;
  }
  .footer{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #232323;
    text-align: right;
    padding: 10px 28px;
    z-index: 9;
    .el-button{
      width: 90px;
      color: #FFFFFF;
    }
  }
}
</style>
<style scoped lang="scss">
::v-deep{
  .el-form{
    font-size: 16px;
    .el-form-item.is-required:not(.is-no-asterisk){
    .el-form-item__label{
      &:before{
        content: '';
      }
      &:after{
        content: "*";
        color: #F56C6C;
        margin-left: 5px
      }
    }
    }
    .el-form-item__label{
      font-size: 16px;
    }
  }
  .el-input__inner{
    background: #232323;
    border-radius: 6px;
    border: 1px solid #4F4F4F;
    width: 100%;
  }
  .el-select{
    width: 100%;
  }
  .el-radio__inner{
    border: 1px solid #4F4F4F;
    width: 20px;
    height: 20px;
    &:after{
      background: #FFFFFF;
      width: 10px;
      height: 10px;
    }
  }
  .is-disabled{
    .el-input__inner{
      background: #4F4F4F;
    }
  }
  .el-checkbox__label{
    font-size: 16px;
    color: #E8E8E8!important;
  }
  .el-checkbox__inner{
    border-color:#767676;
    &:after{
      border-color: #FFFFFF;
    }
  }
  .el-button{
    border-radius: 6px;
    border: 1px solid rgba(255,255,255,0.2);
  }
  .headerStyle{
    background: #363636!important;
  }
}
</style>
